@media only screen and (max-width: 935px){

    .ProductContainer
    {
        flex-direction: column !important;
        .Row1
        {
            flex-direction: column !important;
            .Field
            {
                width: 100% !important;
                margin-bottom: 20px;
            }
            .ant-input-group-wrapper
            {
                width: 100% !important;
            }
        }
    }
}

.ProductContainer
{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .Row1
    {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .LeftSide
    {
        padding: 5px 25px 5px 0px;
        width: 100%;
    }
    .RightSide
    {
        padding: 1.25rem 25px;
        background-color: $TablesBackground;
        border-radius: 15px;
        width: 100%;
    }
    .smallTitle 
    {
        font-family: 'MontserratBold';
        color: $SecondRed;
        font-size: 1rem;
    }
    .Field 
    {
        font-family: 'CatamaranBold' ;
        margin-bottom: 5px;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 1px #2a2a2a2d solid;
        padding: 8px 10px;
        border-radius: 10px;
        transition: all 0.2s ease;

    }
    .Field:hover
    {
        border: 1px solid $FadedPink;
    }
    .Field:focus
    {
        border: 1px solid $CreatePink;
    }
}
