@import './Fonts.scss';
@import './Utils.scss';
@import './Shapes.scss';
@import './SignForm.scss';
// @import './ant-picker.scss';
@import './FormTransitions.scss';

// Mobile 

@import './Mobile/Auth_Mob.scss';


body 
{
    background-color: $LightBackground;
    margin: 0 auto;
    height: 100vh;
    overflow: auto;
    .AuthContainer
    {
        height: 100%;
        .Header
        {
            width: 416px;
            margin-left: 55px;
        }
        .split
        {
            height: 100%;
            position: fixed;
            z-index: 1;
            top: 0;
            overflow-x: hidden;
            padding-top: 1.25rem;
        }
        .auth-sidebar
        {  
            width: 39%;
            left: 0;
            background-image: url('../Assets/res/codscale-background.png');
            background-position: center;
            background-repeat: no-repeat ;
            background-size: cover;
            .Illus
            {   
                svg 
                {
                    width: 700px;
                }
            } 
        }

        .content
        {
            overflow-y: auto;
            width: 59%;
            right: 0;
            margin: 0;
            padding: 0;

            .Container
            {
                max-width: 516px;
                padding: 30px 60px 0;
                margin-bottom: 50px;
                margin-top: 50px;
            }
        }
       
    }
    .EmailContainer
    {
        padding: 1.25rem 15px;
        height: 90vh;

        #bg
        {
            position: fixed; 
            top: 0; 
            left: 0; 
            width: 90%;
            /* Preserve aspet ratio */
            min-width: 100%;
            min-height: 100%;
            z-index: -1;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .Icon
        {
            justify-self: flex-start;
        }
        .PreviewContainer
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40%;
            padding: 1.25rem 15px;
            border-radius: 15px;
            background-color: $TextYellow;
            margin-top: 50px;
            .Icon
            {
                width: 100px;
            }
            .title{
                font-family: 'MontserratBlack';
                font-size: 35px;
                color : $MainPurple;

            }
            
            .description{
                font-family: 'CatamaranBold';
                font-size: 1.25rem;
                color : $TextPurple; 
                width: 80%;
                text-align: center;
            }
            .LoginButt{

                margin-top: 22px;
                margin-bottom: 1.25rem;
                overflow: auto;
                background-color: $SecondRed;
                color: $TextYellow;
                padding: 10px 15px;
                cursor: pointer;
                border: none;
                font-size: 0.938rem;
                font-family: 'CatamaranBold' ;
                border-radius: 10px;
                transition: all 0.2s ease;
                outline: none;
            }
            .LoginButt:hover
            {
                box-shadow: 0px 0px 10px  #ec4f5f38;
                -webkit-transform: translate(0px, -3px);
                -ms-transform: translate(0px, -3px);
                transform: translate(0px, -3px);
                transition: all 0.2s ease;
            }
        }
        
    }
    
}