
@media only screen and (max-width: 935px){

    .Web
    {
        display: none !important;
    }
    .Mobile
    {
        display: flex !important;
    }
    .Title
    {
        margin-left: 10px !important;
    }
}

:root {
    --bg:  #ffffff;
    --bg-accent: #d6d6d6;
    --text-color: #2a2a2a;
    --nav-size: 60px;
    --border: none;
    --border-radius: 10px;
    --speed: 500ms; 
}
.Header
{
    display: flex;
    align-content: baseline;
    width: inherit;
    padding: 10px 25px;
    justify-content: space-between;
    .Web
    {
        display: block;
    }
    .Mobile
    {
        display: none;
    }
    .LeftPart
    {
        width: 20%;
        display: flex;
        align-items: center;
    }
    .RightPart
    {
        width: 20%;
        display: flex; 
        align-items: center;
        justify-content: space-between;
    }
    .MenuIcon
    {
        fill: $MainPurple;
        width: 24px;
        height: 24px;
        margin-right: 1.25rem;
        cursor: pointer;
        transition: all 0.2s ease;
    }
    .MenuIcon:hover
    {
        fill: $BackgroundPurple;
    }
    .Title
    {
        font-family: 'MontserratBold';
        color: $MainPurple;
        font-size: 1.25rem;
    }
    .fadeout {
        
        transform: translateY(-250%);
        -moz-transition: transform 0.4s ease-in-out;
       -o-transition: transform 0.4s ease-in-out;
        -webkit-transition: transform 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out;
    }
    .fadein {
       // opacity: 1;
        transform: translateY(0%);
        transition: transform 0.4s ease-in-out;
        //transition: opacity 0.4s ease-in-out;
    }
    .Dropdown
    {
        
        top: 60px;
        right: 50px;
        z-index: 100;
        position: absolute;
        width: 200px;
        background-color: var(--bg);
        border: var(--border);
        border-radius: var(--border-radius);
        padding: 1rem;
        overflow: hidden;
        box-shadow: 0px 10px 50px rgba(0,0,0,0.1);
        .menuItem
        {
            font-family: 'CatamaranBold';
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: var(--border-radius);
            transition: background var(--speed);
            padding: 0.5rem;
            cursor: pointer;
            font-size: 0.938rem;
        }
        .menuItem:hover
        {
            color: #0d0c22;
            background-color: rgba(13, 12, 34, 0.068);
        }
    }
    .icon-button {
        --button-size: calc(var(--nav-size) * 0.5);
        width: var(--button-size);
        height: var(--button-size);
        background-color: #c7c7c724;
        border-radius: 50%;
        padding: 5px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: filter 300ms;
    }
      
    .icon-button:hover {
      opacity: 0.9;
    }
    
    .icon-button svg { 
      fill: var(--text-color);
      width: 1.25rem;
      height: 1.25rem;
    }
    .NotificationButton
    {   
        fill: $MainPurple;
        width: 18px;
        height: 18px;
        padding: 7px;
        transition: all 0.2s ease;
        border-radius: 50%;
        cursor: pointer;
    }
    .NotificationButton:hover
    {
        background-color: #422a4c07;
    }
    .UserContainer:hover
    {
        background-color: #422a4c07;
    }
    .UserContainer
    {
        display: flex;
        align-content: center;
        padding: 10px 15px;
        transition: all 0.2s ease;
        border-radius: 15px;
        cursor: pointer;
        .UserImg 
        {
            border-radius: 50%;
            object-fit: cover;
            object-position: 30%;
            margin-right: 10px;
        }
        .UserName
        {
            font-family: 'CatamaranBold';
            color: $MainPurple;
            font-size: 0.875rem;
            align-self: center;
        }
    }
}

@media only screen and (max-width: 935px){
    
    .MenuIcon
    {
        fill: $MainPurple;
        width: 1.25rem !important;
        height: 1.25rem !important;
        margin-right: 5px !important;
        cursor: pointer;
        transition: all 0.2s ease;
    }
    .LeftPart
    {
        width: 45% !important;

    }
    .UserName
    {
        display: none;
    }
}