.ProfileContainer
{
    .ProfileSideBar
    {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        background-color: #f2f3f54d;
        font-family: 'CatamaranBold';
        font-size: 0.938rem;
        width : 25%;
        .CloseContainer
        {

            fill: #6A7480;
            cursor: pointer;
            transition: fill 0.2s ease-in-out;
            svg
            {
                width: 35px;
                height: 35px;
            }
    
        }
        .CloseContainer:hover
        {
            fill: #a1a2a3;

        }
        .LinksContainer
            {
                position: relative;
                overflow: hidden;
                margin-left: 40px;
                padding: 60px 6px 60px 1.25rem;
                .title
                {
                    color : #6A7480;
                    font-family: "CatamaranBlack";
                    height: 38px;
                    padding: 0px 10px;
                    margin-bottom: 8px;
                    overflow: hidden;
                    flex: 0 1 auto; 
                    display: flex;
                    flex-direction: column;
                    border-radius: 4px;
                    justify-content: center;
                    width: 80%;
                    cursor: pointer;
                }
                .NavContainer
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: left;
                    width: 70%;
                    .active 
                    {
                        background-color: #DADDE1;
                    }
                    li 
                    {
                        height: 38px;
                        display: flex;
                        padding: 0px 10px;
                        margin-bottom: 8px;
                        overflow: hidden;
                        flex: 0 1 auto;  
                        color: #060607;
                    }
                    .nav-item
                    {
                        display: flex;
                        flex-direction: column;
                        border-radius: 8px;
                        justify-content: center;
                        width: 80%;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        span 
                        {
                            justify-self: center;
                            white-space: nowrap ;
                            font-size: 0.875rem;
                        }
                        i 
                        {
                            padding: 5px 15px;
                            svg { 
                                fill: $TextYellow; 
                                height: 18px;
                                width: 18px;
                                margin-right: 5px;
                            }
                        }
                        
                    }
                    .nav-item:hover
                    {
                        background-color: #DADDE1;
                    }
                }
            }
    }
}