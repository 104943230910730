.Logo
{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.MainfourContainer
{
    height: 90vh;
    padding: 0 1.25rem;
}
.FourContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    .Body
    {
        .descSection
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .desc
            {
                font-family: 'MontserratBlack' ;
                font-size: 1.25rem;
                color: $MainPurple;
                margin-bottom: 10px;
            }
            .CreateOrder
            {
                button 
                {
                    display: flex;
                    align-items: center;
                    font-family: 'CatamaranBold';
                    background-color: $SecondRed;
                    text-align: center;
                    border: 1px solid transparent;
                    border-radius: 10px;
                    padding: 10px 70px;
                    color: $TextYellow;
                    font-size: 1.125rem;
                    outline: none;
                    transition: all 0.2s ease;
                    cursor: pointer;
                    margin-right: 15px;
                    svg 
                    {
                        margin-right: 5px;
                        fill :  $TextYellow ;
                        width: 12px;
                        height: 12px;
                    }
                }
                button:hover
                {
                    box-shadow: 0px 0px 10px  #ec4f5f38;
                    -webkit-transform: translate(0px, -3px);
                    -ms-transform: translate(0px, -3px);
                    transform: translate(0px, -3px);
                    transition: all 0.2s ease;
                }
            }
        }
    }
    #bg
    {
        position: fixed; 
        top: 0; 
        left: 0; 
        width: 90%;
        /* Preserve aspet ratio */
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
    }
}