@media only screen and (max-width: 935px){

    .headCont
    {
        flex-direction: column;
        
    }
    .OrderContainer
    {
        flex-direction: column;

    }
    .LeftSide
    {
        width: 100% !important;
    }
    .MiddleSide
    {
        width: 100% !important;
        background-color: transparent !important;
        padding: 0 !important;
    }
    .RightSide
    {
        width: 100% !important;
        margin-top: 35px;
        background-color: transparent !important;
        padding: 0 !important;
    }
    .Recap
    {
            background-color: transparent !important;
        padding: 0 !important;
    }
    .BottomPart
    {
        flex-direction: column;
        margin-top: 10px;
        width: 100% !important;
        .Recap
        {
        width: 100% !important;
            
        }
    }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
    width: initial !important;
}
.OrderContainer
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .Add
    {
        margin-top: 10px;
        margin-bottom: 10px;
        outline: none;
        cursor: pointer;
        padding: 8px 10px;
        font-size: 0.75rem;
        border-radius: 10px;
        font-family: 'CatamaranBold' ;
        border: none;
        color: $TextYellow;
        opacity: 1;
        transition: opacity 0.2s ease;
    }
    .Product 
    {
        background-color: $SelectedBlue;
        margin-right: 10px;
    }
    .Upsell
    {
        background-color: $SecondRed;
    }
    .Add:hover
    {
        opacity: 0.9;
    }
    .smallTitle 
    {
        font-family: 'MontserratBold';
        color: $SecondRed;
        font-size: 1rem;
    }
    .Field 
    {
        font-family: 'CatamaranBold' ;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 1px #2a2a2a2d solid;
        padding: 8px 10px;
        border-radius: 10px;
        box-sizing: border-box;
        transition: all 0.2s ease;

    }
    .Create:hover
    {
        border: 1px solid $FadedPink;
    }
    .Create:focus
    {
        border: 1px solid $CreatePink;
    }
    .LeftSide
    {
        width: 28%;
        .Details
        {
            padding: 5px 25px 5px 0px;
           .dataFields
           {
                margin-top: 10px;
                .Field
                {
                    width: 100%;
                    margin-top: 10px;
                    margin-right: 10px;
                }
                .Row1
                {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    //justify-content: space-between;
                    //align-items: baseline;
                    margin-bottom: 10px;
                }
                .Row2
                {
                   
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                textarea
                {
                    width: 100%;
                    resize: none;
                }
           }
        }
    }
    .MiddleSide
    {
        padding: 1.25rem 25px;
        background-color: $TablesBackground;
        border-radius: 15px;
        width: 28%;
        .Shipping
        {
            
            .dataFields
            {
                margin-top: 10px;
                .Field
                {
                    padding: 5px 15px;
                }
                textarea
                {
                    width: 100%;
                    margin-top: 10px;
                    resize: none;
                }
            }
            .dropDown
            {
                font-family: 'CatamaranBold' ;
            }
        }
    }
    .RightSide
    {
        padding: 1.25rem 25px;
        background-color: $TablesBackground;
        border-radius: 15px;
        width: 30%;
        .CustomerDetails
        {
            .dataFields
            {
                margin-top: 10px;
            }
            .Row1
            {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            
            .Row2
            {
               
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            textarea
            {
                width: 100%;
                resize: none;
            }
        }
    }
    .BottomPart
    {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .CreateP
        {
            width: 70%;
        }
        .RowP
        {
            width: 60%;
        }
        .Products
        {
            padding: 5px 25px 5px 0px;
            #ListOfProducts
            {

                text-align: left;
                margin-top: 10px;
                width: 100%;
                border-spacing: 0 15px;
                .ant-select-arrow
                {
                    svg 
                    {
                        fill :  $CreatePink ;
                        width: 12px;
                        height: 12px;
                        transition: all 0.2s ease;
                    }
                }
                th
                {
                    font-family: 'CatamaranBold' ;
                }
                .ic
                {
                    svg 
                    {
                        fill :  $CreatePink ;
                        width: 18px;
                        height: 18px;
                        margin-left: 15px;
                        cursor: pointer;
                        transition: all 0.2s ease;
                    }
                    svg:hover 
                    {
                        fill :  $CreatePink ;
                    }
                }
            }
           
        }
        .CreateR
        {
            width: 20%;
        }
        .RowR 
        {
            width: 30%;
        }
        .Recap
        {
            margin-top: 35px;
            padding: 1.25rem 25px;
            background-color: $TablesBackground;
            border-radius: 15px;
            font-family: 'CatamaranBold' ;
            .Label
            {
                color: $MainPurple;
                margin-right: 35px;
            }
            .Value
            {
                color: $SecondRed;
            }
        }
        
    }
    
}