@media only screen and  (max-width : 935px)
{
        /* width */
        
    .Routes
    {
        .Web
        {
            display: none !important;
        }
        .Mobile
        {
            display: block !important;
        }
        .SideBar
        {
            position: fixed !important;
            top: 30px !important;
            left: 10px !important;
            height: 80vh !important;
            display: block;
            width: 90vw !important;
            background-color: $MainPurple;
            font-family: 'CatamaranBold';
            transition: width 0.5s ease;
            border-radius: 10px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            
            a {
            color: $TextYellow;
            text-decoration: none;
            display: flex;
            align-items: center;
            }
             
            z-index: 1000 !important; 
        }
        .CloseContainer
        {
            display: block !important;
            position: absolute;
            right: 25px;
            top : 25px ; 
            width: 30px;
            height: 30px;
            fill : $TextYellow;
        }
        .navbar-nav
        {
            display: flex;
            flex: 1 1 auto;
            visibility: visible;
            position: fixed;
            width: 90vw !important;
            height: 80vh !important;
            left: 10px !important;
            top: 30px !important;
            flex-direction: column;
            background-color: inherit;
            border-radius: 10px;
        }
        .LinksContainer
            {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                .NavContainer
                {
                    position: absolute;
                    inset: 0px;
                    overflow-y: auto;
                    margin-right: -5px;
                    margin-bottom: -5px;
                    .active 
                    {
                        background-color: $BackgroundPurple;
                    }
                    li 
                    {
                        height: 58px;
                        display: flex;
                        padding: 0px 10px;
                        margin-bottom: 8px;
                        overflow: hidden;
                        flex: 0 1 auto;  
                    }
                    .nav-item
                    {
                        display: flex;
                        flex: 1 1 auto;
                        border-radius: 10px;
                        cursor: pointer;
                        span 
                        {
                            justify-self: center;
                            white-space: nowrap ;
                            font-size: 1.125rem;
                        }
                        i 
                        {
                            padding: 5px 15px;
                            svg { 
                                fill: $TextYellow; 
                                height: 22px;
                                width: 22px;
                                margin-right: 5px;
                            }
                        }
                        
                    }
                }
            }
        
    }
    .Content
    {
        background-color: $LightBackground;
        z-index: 0 !important;
        margin-left: 0 !important;
    }
}