/* CSSTransition classes  */
.menu {
    
    max-width: 516px;
    width: 516px;
    //overflow-x: hidden;
    transition: height 500ms ease;
  }
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
    opacity: 1;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    opacity: 0;
    transform: translateX(-110%);
    transition: all 500ms ease;
  }

  // secondary 
  .menu-secondary-enter {
    transform: translateX(110%);
    opacity: 1;
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
  }
  .menu-secondary-exit {

  }
  .menu-secondary-exit-active {
    opacity: 0;
    transform: translateX(-110%);
    transition: all 500ms ease;
  }
  // -----------------------------------------
  // third
    .menu-third-enter {
      transform: translateX(110%);
      opacity: 1;
    }
    .menu-third-enter-active {
      transform: translateX(0%);
      transition: all 500ms ease;
    }
    .menu-third-exit {

    }
    .menu-third-exit-active {
      opacity: 0;
      transform: translateX(110%);
      transition: all 500ms ease;
    }