@media only screen and (max-width: 935px){
    .Row2
    {
        flex-direction: column;
        
    }
    .InventorySelect
    {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .Row1
    {
        width: 100% !important;
    }
}


.InventoryContainer
{
    .smallTitle 
    {
        font-family: 'MontserratBold';
        color: $SecondRed;
        font-size: 1rem;
    }
    .Field 
    {
        font-family: 'CatamaranBold' ;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 1px #2a2a2a2d solid;
        padding: 8px 10px;
        border-radius: 10px;
        box-sizing: border-box;
        transition: all 0.2s ease;

    }

    .edit:hover
    {
        border: 1px solid $FadedPink;
    }
    .edit:focus
    {
        border: 1px solid $CreatePink;
    }
   
    .Row1
    {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .Row2
    {
        margin-top: 10px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px; 
    }
    .back
    {
        padding: 1.25rem 1.25rem;
        background-color: $TablesBackground;
        border-radius: 15px;
        font-family: 'CatamaranBold' ;
    }
    label
    {
        font-family: 'CatamaranBold' ;
        color: $SelectedBlue;
        font-size: 0.938rem;
        margin-bottom: 5px;
    }
}