@import './Ordermodal.scss';

@media only screen and (max-width: 935px){
    .MenuContainer
    {
        width: 100%;
        flex-direction: column !important;
        align-items: flex-start !important; 
    }
    .autoComp
        {
            width: 100%;
            flex-direction: column;
            align-items: flex-start !important;
        }
        .ClearFilter
        {
            padding: 5px 10px !important;
        }
        .Search
        {
            padding: 5px 10px !important;
        }
}
.StatusComponent
        {
            display: flex;
            i 
            {
                margin-right: 5px;
                svg 
                {
                    width: 12px;
                    height: 12px;
                }
            }
           
        }
        .pending
        {
            svg
            {
                fill : $PendingOrange ;
            }
            span 
            {
                color: $PendingOrange;
            }
        }
        .confirmed 
        {
            svg
            {
                fill : $ConfirmedGreen ;
            }
            span 
            {
                color: $ConfirmedGreen;
            }
        }
        .declined 
        {
            svg
            {
                fill : $RedColor ;
            }
            span 
            {
                color: $RedColor;
            }
        }
.MainContainer
{
    padding: 10px 25px;
    
    .MenuContainer
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .disabled
    {
        display: flex;
        align-items: center;
        font-family: 'CatamaranBold';
        background-color:  #F5F5F5 ;
        border: 1px dashed rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 2px 5px;
        outline: none;
        transition: all 0.2s ease;
        cursor: not-allowed;
        button 
        {
            background-color:  #F5F5F5 ;
            border: none;
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.25);
            font-family: 'CatamaranBold';
            display: flex;
            align-items: center;
            svg 
            {
                margin-right: 5px;
                fill : rgba(0, 0, 0, 0.25) ;
                width: 18px;
                height: 18px;
            }
        }
        
    }
    .getOrder
    {
        display: flex;
        align-items: center;
        font-family: 'CatamaranBold';
        background-color:  $TextYellow ;
        border: 1px dashed #6d2eab;
        border-radius: 8px;
        padding: 2px 5px;
        outline: none;
        transition: all 0.2s ease;
        cursor: pointer;
        
        button 
        {
            background-color:  $TextYellow ;
            border: none;
            cursor: pointer;
            color: #6d2eab;
            font-family: 'CatamaranBold';
            display: flex;
            align-items: center;
            svg 
            {
                margin-right: 5px;
                fill :  #6d2eab ;
                width: 18px;
                height: 18px;
            }
        }
    }
    .getOrder:hover
    {
        box-shadow: 0px 0px 10px  #ec4f5f38;
        -webkit-transform: translate(0px, -3px);
        -ms-transform: translate(0px, -3px);
        transform: translate(0px, -3px);
        transition: all 0.2s ease;
    }
    .CreateOrder
    {
        button 
        {
            display: flex;
            align-items: center;
            font-family: 'CatamaranBold';
            background-color: #6d2eab;
            border: 1px solid transparent;
            border-radius: 10px;
            padding: 8px 10px;
            color: $TextYellow;
            outline: none;
            transition: all 0.2s ease;
            cursor: pointer;
            margin-right: 15px;
            svg 
            {
                margin-right: 5px;
                fill :  $TextYellow ;
                width: 12px;
                height: 12px;
            }
        }
        button:hover
        {
            box-shadow: 0px 0px 10px  #ec4f5f38;
            -webkit-transform: translate(0px, -3px);
            -ms-transform: translate(0px, -3px);
            transform: translate(0px, -3px);
            transition: all 0.2s ease;
        }
    }
    .autoComp
    {
        width: 40% ;
        justify-content: flex-end;
    }
    .ButtonsContainer
    {
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 15px;
        .ClearFilter
        {
            font-family:"CatamaranBold";
            color:#4b4b4b;;
            cursor:pointer;
            margin-right:5px;
            font-size:12px
        }
        .ClearFilter:hover
        {
            color:#313131;
            
        }
        .refresh
        {   
            fill: #4b4b4b;
            width: 15px;
            height: 15px;
            padding: 7px;
            transition: all 0.2s ease;
            border-radius: 5px;
            cursor: pointer;
        }
        .refresh:hover
        {
            background-color: #ededed;
        }
        .Search
        {
            fill: $MainPurple;
            transition: all 0.2s ease;
            border-radius: 10px;
            cursor: pointer;
            padding: 7px;
        }
        .Search:hover
        {
            background-color: #ededed;
        }
    }
    .ListContainer
    {
        font-family: 'CatamaranBold';
        td
        {
            color : rgba(0,0,0,.65) !important;
        }
    }
}