@media only screen and (max-width: 935px){
    
    body 
{
  
    .AuthContainer
    {
        height: 100%;
        .Header
        {
            width: 416px;
            margin-left: 55px;
            display: flex;
        }
        .split
        {
            height: 100%;
            position: relative;
            overflow-x: hidden;
            padding-top: 1.25rem;
        }
        .auth-sidebar
        {  
            display: none;
        }

        .content
        {
            overflow-y: auto;
            width: 100% !important ;
            right: 0;
            margin: 0;
            padding: 0;

            .Container
            {
                max-width: 100% !important;
                padding: 30px 60px 0;
                margin-bottom: 50px;
                margin-top: 50px;
            }
        }
       
    }
    .RespCont
    {
        display: flex;
        justify-content: center;
        width: 100%;
        .RespLogo
        {
            cursor: pointer;
            width: 200px;
            height: 100px;
            background-image: url('../../Assets/Icons/wl.png');
            background-position: center;
            background-repeat: no-repeat ;
            background-size: contain;
            
        }
    }
    
}
}
