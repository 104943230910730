.CardContainer
{
    .inv_msg
    { 
      margin-top: 4px;
      padding-left: 3px;
      padding-bottom: 4.5%;
      font-size: 13px;
      font-weight: bold;
      color : #D8000C ;
      font-family: 'Glacial indifference';
    
    }
    .inv,
    .inv:-webkit-autofill,
    .inv:-webkit-autofill:hover, 
    .inv:-webkit-autofill:focus, 
    .inv:-webkit-autofill:active
    { 
      background-color: #d8000b19;
    }
    p
    {
        font-family: 'CatamaranRegular' ;
        font-size: 0.938rem;
        color: $TextPurple;
    }
    .TwoFields
    {
        display: flex;
        width: 100%;
        margin-top: 10px;
        justify-content: space-between;
    }
    .Field1 , .Field2
    {
        width: 45%;
    }
    .label
    {
        display: block;
        font-family: 'CatamaranBold' ;
        font-size: 1rem;
        color: $MainPurple;
        margin: 14px 0 4px;
    }
    .Field 
    {
        width: 100%;
        font-family: 'CatamaranBold' ;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 2px solid transparent;
        padding: 10px 16px;
        border-radius: 10px;
        box-sizing: border-box;
        height: 40px;
        transition: all 0.2s ease;
        box-shadow: 0px 0px 5px #2a2a2a29;
        margin-top: 10px;
    }
    .Field:hover
    {
        border: 2px solid $FadedPink;
    }
    .Field:focus
    {
        border: 2px solid $CreatePink;
    }  
}