@media only screen and (max-width: 935px) {
  .Row2 {
    .EmployeSelect {
      width: 100% !important;
      margin-top: 15px;
    }
  }
}
.EmployeContainer {
  .Field {
    font-family: "CatamaranBold";
    color: $MainPurple;
    outline: none;
    box-sizing: border-box;
    border: 1px #2a2a2a2d solid;
    padding: 8px 10px;
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 0.2s ease;
  }
  .err {
    border: 1px #d8000c solid;
    background-color: #d8000b4f;
  }

  .edit:hover {
    border: 1px solid $FadedPink;
  }
  .edit:focus {
    border: 1px solid $CreatePink;
  }

  .Row1 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .Row2 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .back {
    padding: 15px 1.25rem;
    background-color: $TablesBackground;
    border-radius: 15px;
  }
  label {
    font-family: "CatamaranBold";
    color: $SelectedBlue;
    font-size: 0.938rem;
    margin-bottom: 5px;
  }
  span {
    transition: opacity 0.2s ease-in-out;
  }
  .Error {
    font-family: "CatamaranBold";
    color: #d8000c;
    opacity: 1;
  }
  .empty {
    opacity: 0;
  }
}
