@media only screen and (max-width: 935px){
    .Row1
    {
        flex-direction: column !important;
    }
    .Select
    {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .LeftSide
    {
        width: 100% !important;
        flex-direction: column !important;
        .Recap
        {
            width: 100% !important;
        }
    }
}

.BonContainer
{
    width: 100%;
    .DataContainer
    {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .label
    {
        font-family: 'CatamaranBold' ;
        color: $SelectedBlue;
        font-size: 1.063rem;
        margin-bottom: 5px;
    }
    .Field
    {
        font-family: 'CatamaranBold' ;
        margin-top: 5px;
        margin-bottom: 5px;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        background-color: $TablesBackground;
        border: 1px transparent solid;
        padding: 2px 10px;
        border-radius: 10px;
        box-sizing: border-box;
        transition: all 0.2s ease;

    }
    .smallTitle 
    {
        font-family: 'MontserratBold';
        color: $SecondRed;
        font-size: 1.125rem;
    }
    .LeftSide
    {
        padding: 5px 25px 5px 0px;
        width: 50%;
        .Row1
        {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }
    .PdfDownload
    {
        padding: 8px 10px;
        border-radius: 10px;
        margin-top: 10px;
        color : #2a2a2a;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        svg 
        {
            fill :  #2a2a2a ;
            width: 18px;
            height: 18px;
        }
    }
    .PdfDownload:hover
    {
        background-color: #ebebeb80;
    }
    .RightSide
    {
        padding: 1.25rem 25px;
        margin-bottom: 5px;
        background-color: $TablesBackground;
        border-radius: 15px;
        font-family: 'CatamaranBold' ;
        
        .StatusComponent
        {
            display: flex;
            i 
            {
                margin-right: 5px;
                svg 
                {
                    width: 10px;
                    height: 10px;
                }
            }
           
        }
        .pending
        {
            svg
            {
                fill : $PendingOrange ;
            }
            span 
            {
                color: $PendingOrange;
            }
        }
        .confirmed 
        {
            svg
            {
                fill : $ConfirmedGreen ;
            }
            span 
            {
                color: $ConfirmedGreen;
            }
        }
    }
}