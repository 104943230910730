@import '../../../Sass/FormTransitions.scss';
@import './SaleModal.scss';
@import './RowSales.scss';
.SalesContainer
{
    
    .NoData
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 25px;
        margin-top: 1.25rem;
    }
    .title
    {
        font-family: 'CatamaranBlack';
        font-size: 28px;
        color : $MainPurple ;
    }
    .ImageContainer
    {
        padding: 30px 10px;
        
    }
    .CreateOrder
    {
        button 
        {
            display: flex;
            align-items: center;
            font-family: 'CatamaranBold';
            background-color: $CreatePink;
            border: 1px solid transparent;
            border-radius: 10px;
            padding: 8px 10px;
            color: $TextYellow;
            outline: none;
            transition: all 0.2s ease;
            cursor: pointer;

            svg 
            {
                margin-right: 5px;
                fill :  $TextYellow ;
                width: 12px;
                height: 12px;
            }
        }
        button:hover
        {
            box-shadow: 0px 0px 10px  #ec4f5f38;
            -webkit-transform: translate(0px, -3px);
            -ms-transform: translate(0px, -3px);
            transform: translate(0px, -3px);
            transition: all 0.2s ease;
        }
    }

}