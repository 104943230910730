// Paragraphes ans others -------------------
@font-face {
    font-family: 'CatamaranBlack';
    src: url('../Assets/Fonts/Nunito-Black.ttf');
}
@font-face {
    font-family: 'CatamaranBold';
    src: url('../Assets/Fonts/Nunito-Bold.ttf');
}
@font-face {
    font-family: 'CatamaranRegular';
    src: url('../Assets/Fonts/Nunito-Regular.ttf');
}

// -----------------------------------------

//  Titles font -----------------------

@font-face {
    font-family: 'MontserratBlack';
    src: url('../Assets/Fonts/Nunito-Black.ttf');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../Assets/Fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('../Assets/Fonts/Nunito-Regular.ttf');
}

// -----------------------------------------