.Account
{
    .LangContainer
    {
        margin-top: 1.25rem;
        .LangRow
        {
            color : #060607;
            font-family: 'CatamaranBold';
            padding: 8px 1.25rem;
            width: 80%;
            background-color: #eeeeee;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
}