.RowSaleContainer
{
    .fieldCont
    {
        .label
        {
            font-family: 'CatamaranBold' ;
            color: $SelectedBlue;
            font-size: 1.063rem;
            margin-bottom: 5px;
        }
        .Value
        {
            font-family: 'CatamaranBold' ;
            color: #6A7480;
        }
    }
    .readOnly
    {
        padding: 10px 15px;
        border-radius: 10px;
        background-color: #F6F6F6;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }
    .Editable
    {
        margin-top: 1.25rem;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: #F6F6F6;
        .label
        {
            display: block;
            font-family: 'CatamaranBold' ;
            font-size: 1rem;
            color: #6A7480;
            margin: 14px 0 4px;
        }
        .Field 
        {
            width: 100%;
            font-family: 'CatamaranBold' ;
            color: $MainPurple;
            outline: none;
            box-sizing: border-box;
            border: 2px solid transparent;
            background-color: #fcfcfc;
            padding: 10px 16px;
            border-radius: 10px;
            box-sizing: border-box;
            height: 40px;
            transition: all 0.2s ease;
            box-shadow: 0px 0px 15px #2a2a2a29;
        }
        .Field:hover
        {
            border: 2px solid $FadedPink;
        }
        .Field:focus
        {
            border: 2px solid $CreatePink;
        }

    }
}