.Account
{
    .titlePlan
    {
        font-family: 'CatamaranBold';
        color : $SelectedBlue ;
        font-size: 1.25rem;
    }
    .actButton
    {
        button 
        {
            font-family: 'CatamaranBold';
            border : none ;
            border-radius: 8px;
            line-height: 16px;
            padding: 2px 30px 2px 30px;
            height: 35px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            outline : none ;
            color: #f2f3f5;
            background-color: $MainPurple;
        }
        button:hover 
        {
            box-shadow: 0px 0px 10px  rgba(156, 185, 209, 0.452);
            -webkit-transform: translate(0px, -3px);
            -ms-transform: translate(0px, -3px);
            transform: translate(0px, -3px);
            transition: all 0.2s ease;
        }
    }
    .titlePrice
    {
        font-family: 'CatamaranBold';
        color : $SelectedBlue;
        font-size: 1.125rem; 
    }
    .UpgradePlan
    {
        padding: 1.25rem 40px 1.25rem 40px;
        background-color: #fcfcfc;
        width: 20%;
        border-radius: 10px;
        background-color: #f2f3f54d;
        transition: all 0.2s ease-in-out;
        margin-bottom: 10px;
        box-shadow: 0px 0px 15px  rgba(156, 185, 209, 0.267);
    }

    .titleCurrPlan
    {
        font-family: 'CatamaranBlack';
        color : $MainPurple;
        font-size: 1.563rem;
    }
    .PlanCont
    {
        margin-top: 15px;
        border-radius: 10px;
        background-color: #fcfcfc;
        padding: 16px 32px 16px 32px;
        height: 200px;
        
    }
    .CurrentPlan
    {
        width: 40%;
    }
    .Stats
    {
        width: 50%;
        .StatCont
        {
            margin-top: 15px;
            .elemTit
            {
                font-family: 'CatamaranBlack';
                font-size: 1.25rem;

            }
            .perc
            {
                font-family: 'CatamaranRegular';
                color : #6A7480;
                font-size: 0.938rem;
            }
        }
    }
}