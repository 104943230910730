// Colors used in project -----------------------
$MainPurple : #2C1338 ;
$BackgroundPurple : #422A4C ;
$TextYellow : #FFFAE3 ;
$SecondRed : #6D2EAB ;
$SelectedBlue : #2C1338 ;
$CreatePink : #6D2EAB ;
$FadedPink : #6D2EAB59 ;
$ConfirmedGreen : #2B9348 ;
$PendingOrange : #F77F00 ;
$LightBackground : #FCFCFC ; 
$TablesBackground : #F9F9F9 ;
$TextPurple : #2c1338ab ;
$RedColor : #9d0000
// ---------------------------------------------------