.Recap
{
    margin-top: 15px;
    padding: 1.25rem 25px;
    background-color: $TablesBackground;
    border-radius: 15px;
    font-family: 'CatamaranBold' ;

    .Label
    {
        color: $MainPurple;
        margin-right: 35px;
    }
    .Value
    {
        color: $SecondRed;
    }
    
}