@media only screen and (max-width: 935px){

  .TopPart {
    display: flex;
    flex-direction: column !important; 
  }

  .WidgetContainer {
    
    width: 100% !important;
    margin-top: 15px;
    .FlexContainer
    {
      align-items: center;
    }
    .value
    {
      margin-left: 20px;
      margin-top: 10px;
    }

  }

  .bottom
  {
    width: 100% !important;
    height: 100% !important;
  }
}


.MainContainer {
  margin-top: 25px;
  .TopPart {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .WidgetContainer {
    width: 22%;
    height: 110px;
    padding: 15px 26px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px #2a2a2a29;
    .FlexContainer {
      display: flex;
      justify-content: space-between;
      .Title {
        color: $MainPurple;
        font-size: 1.125rem;
        font-family: "CatamaranBold";
      }
      .value {
        font-size: 0.938rem;
        color: #2a2a2a;
        font-family: "CatamaranRegular";
      }
      .RightPart
      {
        margin-left: 10px;
        height: 50px;
        width: 50px;
        // border-radius: 50%;
        // position: relative;
        // background-color: $MainPurple;
        // animation: dotmove cubic-bezier(1,0,0,1) 2s infinite;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
      .Icon {
        svg {
          width: 45px;
          height: 45px;
          // fill: $SecondRed;
          fill: #6d2eab;

        }
      }
    }
    .stats {
      font-size: 0.75rem;
      border-radius: 10px;
      font-family: "CatamaranBold";
      padding: 1px 5px;
      font-size: 0.69444rem;
      position: absolute;
      bottom: 0;
      margin-bottom: 15px;
    }
    .up {
      color: #00864e;
      background-color: #ccf6e4;
    }
    .down {
      color: #e63757 !important;
      background-color: #fce7eb !important;
    }
  }
  .heading {
    font-family: "CatamaranBold";
    font-size: 1.125rem;
    color: $MainPurple;
  }
  .bottom
  {
    width: 60%;
  }
  .headContainer
  {
    padding: 15px 26px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ChartContainer {
    padding: 15px 26px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px #2a2a2a29;
    margin-top: 10px;
    margin-bottom: 10px;
    h2 {
      font-family: "CatamaranBold";
      color: $MainPurple;
    }
    button {
      display: flex;
      align-items: center;
      font-family: "CatamaranBold";
      background-color: $CreatePink;
      border: 1px solid transparent;
      border-radius: 10px;
      padding: 8px 15px;
      outline: none;
      transition: all 0.2s ease;
      cursor: pointer;
      a {
        text-decoration: none;
        color: $TextYellow;

      }
      svg {
        margin-right: 5px;
        fill: $TextYellow;
        width: 12px;
        height: 12px;
      }
    }
    button:hover {
      box-shadow: 0px 0px 10px #ec4f5f38;
      -webkit-transform: translate(0px, -3px);
      -ms-transform: translate(0px, -3px);
      transform: translate(0px, -3px);
      transition: all 0.2s ease;
    }
  }
}
