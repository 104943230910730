
@media only screen and (max-width: 935px) {
    .ClientHeader
    {
        flex-direction: column !important;
    }
    .autoC
    {
        width: 100% !important;
        justify-content: flex-start !important;
    }
}

.autoC
{
    width: 40%;
    justify-content: flex-end;
}
.ClientContainer
{
    .Field 
    {
        font-family: 'CatamaranBold' ;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 1px #2a2a2a2d solid;
        padding: 8px 10px;
        border-radius: 10px;
        box-sizing: border-box;
        transition: all 0.2s ease;

    }
    .err
    {
        border: 1px $SecondRed solid;
        background-color: $FadedPink;
    }

    .edit:hover
    {
        border: 1px solid $FadedPink;
    }
    .edit:focus
    {
        border: 1px solid $CreatePink;
    }
    .Error
    {
        font-family: 'CatamaranBold' ;
        color: $SecondRed;
        opacity: 1;
    }
    .empty
    {
        opacity: 0;
    }
    .edit:hover
    {
        border: 1px solid $FadedPink;
    }
    .edit:focus
    {
        border: 1px solid $CreatePink;
    }
   
    .Row1
    {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .Row2
    {
        margin-top: 10px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px; 
    }
    .back
    {
        padding: 1.25rem 1.25rem;
        background-color: $TablesBackground;
        border-radius: 15px;
    }
    label
    {
        font-family: 'CatamaranBold' ;
        color: $SelectedBlue;
        font-size: 0.938rem;
        margin-bottom: 5px;
    }
}