body 
{   
    .AuthContainer
    {
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        overflow: hidden;

        .botleft
        {
            position: fixed;
            width: 400px;
            bottom: -100px;
            left: -150px;
        }
        .botright
        {
            position: fixed;
            width: 400px;
            bottom: -100px;
            right: -100px;
        }
        .topright
        {
            position: fixed;
            width: 400px;
            top: -100px;
            right: -150px;
        }
    }
}