.LimitContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .TitleLimit
    {
        font-family: 'MontserratBlack' ;
        font-size: 1.563rem;
        color: $MainPurple;
        margin-bottom: 0px;
    }
    .DescriptionLimit
    {
        font-family: 'CatamaranBold' ;
        font-size: 0.938rem;
        color: $MainPurple;
        margin: 10px 0 0 0;
        text-align: center;
    }
    
    .CreateOrder
    {

        button 
        {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'CatamaranBold';
            background-color: $SecondRed;
            border: 1px solid transparent;
            border-radius: 10px;
            padding: 8px 25px;
            color: #fafafa;
            outline: none;
            transition: all 0.2s ease;
            cursor: pointer;
            margin-top: 15px;
        }
        i 
        {
            margin-right: 10px;
            display: flex;
            svg { 
                fill: #fafafa; 
                height: 18px;
                width: 18px;
            }
        }
        button:hover
        {
            box-shadow: 0px 0px 10px  #ec4f5f38;
            -webkit-transform: translate(0px, -3px);
            -ms-transform: translate(0px, -3px);
            transform: translate(0px, -3px);
            transition: all 0.2s ease;
        }
    }
}