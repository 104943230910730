@import './Mobile/SignForm_Mob.scss';

.Title
{
    font-family: 'MontserratBlack' ;
    font-size: 1.875rem;
    color: $MainPurple;
    margin-bottom: 0px;
}

.Plan
{
    margin-top: 10px;
}

.CardContainer
{
    margin-top: 1.25rem;

}
.ContentContainer
{
    margin-top: 20%;
}
.Description
{
    font-family: 'CatamaranBold' ;
    font-size: 0.938rem;
    color: $SelectedBlue;
    margin: 5px 0 0 0;
}
.SecondTitle
{
    font-family: 'CatamaranBold' ;
    font-size: 1.25rem;
    color: #2a2a2a;
}
.Paragraph
{
    font-family: 'CatamaranRegular' ;
    font-size: 0.938rem;
    color: $TextPurple;
}
.SignForm
{
    .inv_msg
    { 
      margin-top: 4px;
      padding-left: 3px;
      padding-bottom: 4.5%;
      font-size: 13px;
      font-weight: bold;
      color : #D8000C ;
      font-family: 'CatamaranBold';
    
    }
    .inv,
    .inv:-webkit-autofill,
    .inv:-webkit-autofill:hover, 
    .inv:-webkit-autofill:focus, 
    .inv:-webkit-autofill:active
    { 
      background-color: #d8000b19;
    }
    .TwoFields
    {
        display: flex;
        width: 100%;
        margin-top: 10px;
        justify-content: space-between;
    }
    .Field1 , .Field2
    {
        width: 45%;
    }
    .label
    {
        display: block;
        font-family: 'CatamaranBold' ;
        font-size: 1rem;
        color: $MainPurple;
        margin: 14px 0 4px;
    }
    .Field 
    {
        width: 100%;
        font-family: 'CatamaranBold' ;
        color: $MainPurple;
        outline: none;
        box-sizing: border-box;
        border: 2px solid transparent;
        padding: 10px 16px;
        border-radius: 10px;
        box-sizing: border-box;
        height: 40px;
        transition: all 0.2s ease;
        box-shadow: 0px 0px 15px #2a2a2a29;
    }
    .Field:hover
    {
        border: 2px solid $FadedPink;
    }
    .Field:focus
    {
        border: 2px solid $CreatePink;
    }
    .SigninButton
    {
        margin-top: 22px;
        margin-bottom: 1.25rem;
        overflow: auto;
        .loadsignin
        {
            background-color: #999999;
            transition: all 0.2s ;
            cursor: not-allowed;
            outline: none;
        }
        .loading
        {
          background-color: #999999;
          transition: all 0.2s ;
          cursor: not-allowed;
          outline: none;
          float: right;
        }
        .back
        {
            background-color: #999999;
            color: #fcfcfc;
            float: left;
            width: 110px;
            padding: 5px;
            cursor: pointer;
        }
        .next
        {
            background-color: $SecondRed;
            color: $TextYellow;
            float: right;
            width: 110px;
            height: 35px;
            cursor: pointer;
        }
        .signin
        {
            background-color: $SecondRed;
            color: $TextYellow;
            width: 110px;
            height: 35px;
            cursor: pointer;
        }
        .signin:hover
        {
            background-color: $MainPurple;
        }
        .Button 
        {
            border: none;
            font-size: 0.938rem;
            font-family: 'CatamaranBold' ;
            border-radius: 10px;
            transition: all 0.2s ease;
            outline: none;
        }
        .next:hover
        {
            // box-shadow: 0px 0px 10px  rgba(156, 185, 209, 0.452);
            background-color: #2C1338ee;
        }
        .back:hover
        {
            background-color: #7c7c7c;
        }
    }
    .smalltext
    {
        font-family: 'CatamaranBold' ;
        font-size: 0.875rem;
        color: $TextPurple;
        margin: 10px 0 0 0;
        a 
        {
            text-decoration: none;
            color: $SecondRed;
            transition: all 0.2s ease;
        }
        a:hover
        {
            text-decoration: underline;
        }
    }
    .privacy
    {
        font-family: 'CatamaranBold' ;
        font-size: 0.875rem;
        color: $MainPurple;
    }
}