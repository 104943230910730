@import './Fonts.scss';
@import './Utils.scss';
@import './LimitReached.scss';
@import './404.scss';
@import '../Routers/helpers/Scss/Header.scss';
@import '../Pages/Orders/Scss/Orders.scss';
@import '../Pages/Products/Scss/Productmodal.scss';
@import '../Pages/Bon/Scss/Bonmodal.scss';
@import '../Pages/Inventory/Scss/Inventory.scss';
@import '../Pages/Staff/Scss/Employes.scss';
@import '../Pages/Clients/Scss/Client.scss';
@import '../Pages/Factures/Scss/Factures.scss';
@import '../Pages/Dashboard/Scss/Dashboard.scss';
@import '../Pages/SalesChannel/Scss/MainSales.scss';
// @import './ant-picker.scss';

// Mobile 

@import './Mobile/Router_Mob.scss';

html { font-size: 100% } 
.Routes
{
    
    ::-webkit-scrollbar {
        width: 9px;
        background: transparent;
        transition: width 0.2s ease;
        margin-right: 3px;
        
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 5px;
        margin-right: 3px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(221, 111, 209);
        border-radius: 5px;
        margin-right: 3px;
        cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(221, 111, 208, 0.57);
    }
    
    .SideBar
    {
       
        
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        background-color: $MainPurple;
        font-family: 'CatamaranBold';
        transition: width 0.5s ease;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        
        a {
          color: $TextYellow;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        
        .CloseContainer
        {
            display: none;
        }
        .navbar-nav
        {
            display: flex;
            flex: 1 1 auto;
            visibility: visible;
            position: fixed;
            width: inherit;
            height: 100%;
            left: 0px;
            top: 0px;
            flex-direction: column;
            background-color: inherit;

            
            .IconContainer
            {
                display: flex;
                align-items: center;
                padding: 10px;
                margin-left: 10px;
                height: 76px;
                
                .Icon 
                {
                    margin-right: 25px;
                }
                span
                {
                    font-family: 'CatamaranBold';
                    color: white;
                    white-space: nowrap ;
                }
            }
            
            .LinksContainer
            {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                .NavContainer
                {
                    position: absolute;
                    inset: 0px;
                    overflow-y: auto;
                    margin-right: -5px;
                    margin-bottom: -5px;
                    .active 
                    {
                        background-color: $BackgroundPurple;
                    }
                    li 
                    {
                        height: 38px;
                        display: flex;
                        padding: 0px 10px;
                        margin-bottom: 8px;
                        overflow: hidden;
                        flex: 0 1 auto;  
                    }
                    .nav-item
                    {
                        display: flex;
                        flex: 1 1 auto;
                        border-radius: 10px;
                        cursor: pointer;
                        span 
                        {
                            justify-self: center;
                            white-space: nowrap ;
                            font-size: 0.875rem;
                        }
                        i 
                        {
                            padding: 5px 15px;
                            svg { 
                                fill: $TextYellow; 
                                height: 18px;
                                width: 18px;
                                margin-right: 5px;
                            }
                        }
                        
                    }
                    .nav-item:hover
                    {
                        background-color: $BackgroundPurple;
                    }
                }
            }   
            .BottomContainer
            {
                width: 100%;

                .Upgrade
                {
                    padding: 0px 10px;
                    .Item 
                    {
                        border: 2px solid $TextYellow ;
                        border-radius: 10px;
                        height: 38px;
                        display: flex;
                        padding: 6px 5px 3px 8px;
                        flex-direction: column;
                        margin-bottom: 8px;
                        overflow: hidden;
                        flex: 0 1 auto;
                        cursor : pointer ;
                        transition: all 0.2s ease;
                        span 
                        {
                            white-space: nowrap ;
                            color:  $TextYellow;
                            font-size: 0.875rem;
                        }
                        i 
                        {
                            margin-right: 10px;
                            svg { 
                                fill: $TextYellow; 
                                height: 18px;
                                width: 18px;
                                margin-right: 5px;
                            }
                        }
                    }
                    .Item:hover 
                    {
                        box-shadow: 0px 0px 10px  rgba(156, 185, 209, 0.452);
                        -webkit-transform: translate(0px, -3px);
                        -ms-transform: translate(0px, -3px);
                        transform: translate(0px, -3px);
                        transition: all 0.2s ease;
                    }    
                    
                }
            }
        }
         
    }
    .Content
    {
        background-color: $LightBackground;
        transition: margin-left 0.5s ease;
        z-index: 600;
    }
}