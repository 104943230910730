
@media only screen and (max-width: 550px){
    
    .SignForm
    {

        .TwoFields
        {
            display: flex;
            width: 100%;
            margin-top: 10px;
            justify-content: space-between;
            flex-direction: column;
        }
        .Field1 , .Field2
        {
            width: 100% !important;
        }
        
        .menu {
            max-width: 100% !important;
            width: 100% !important;
            transition: height 500ms ease;
        }
    }
}