@import './EditModal.scss';
.ProfileContainer
{
    .ProfileResult
    {
        background-color: $LightBackground;
        transition: margin-left 0.5s ease;
        z-index: 600;
        margin-left: 25%;
        .Account
        {
            padding: 60px 6px 60px 1.25rem;
            p
            {
                font-family: 'CatamaranRegular';
                color : #060607;
                padding: 0px 10px;
                font-size: 13px;
            }
            .disableAcc
            {
                .Heading
                {
                
                    font-family: "CatamaranBlack";
                    height: 38px;
                    padding: 0px 10px;
                    margin-bottom: 8px;
                    overflow: hidden;
                    flex: 0 1 auto; 
                    display: flex;
                    flex-direction: column;
                    color : #6A7480;
                    font-size: 0.938rem;
                    justify-content: center;
                }
                .buttonCont
                {
                    display: flex;

                    .delButt
                    {
                        button
                        {
                            border:  2px solid #E50914;
                            color : #E50914;
                            background-color: #fcfcfc;
                        }
                        button:hover
                        {
                            box-shadow: 0px 0px 0px  rgba(156, 185, 209, 0.452);
                            transform: translate(0px, 0px);
                            transition: all 0.2s ease;
                        }
                        margin-right: 15px;
                    }
                    .disButt
                    {
                        button
                        {
                            background-color: #E50914;
                            color : #fcfcfc;
                            
                        }
                        button:hover
                        {
                            box-shadow: 0px 0px 0px  rgba(156, 185, 209, 0.452);
                            transform: translate(0px, 0px);
                            transition: all 0.2s ease;
                        }
                    }
                }
                
            }
            .actButton
            {
                button 
                {
                    font-family: 'CatamaranBold';
                    border : none ;
                    border-radius: 8px;
                    line-height: 16px;
                    padding: 2px 30px 2px 30px;
                    height: 35px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    outline : none ;
                }
                button:hover 
                {
                    box-shadow: 0px 0px 10px  rgba(156, 185, 209, 0.452);
                    -webkit-transform: translate(0px, -3px);
                    -ms-transform: translate(0px, -3px);
                    transform: translate(0px, -3px);
                    transition: all 0.2s ease;
                }
            }
            .rowBut
            {
                button 
                {
                    font-family: 'CatamaranBold';
                    border : none ;
                    border-radius: 8px;
                    line-height: 16px;
                    padding: 5px 30px 5px 30px;
                    height: 35px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    background-color: #4c535c;
                    color : #fcfcfc;
                    outline : none ;

                }
                button:hover 
                {
                    box-shadow: 0px 0px 10px  rgba(156, 185, 209, 0.452);
                    -webkit-transform: translate(0px, -3px);
                    -ms-transform: translate(0px, -3px);
                    transform: translate(0px, -3px);
                    transition: all 0.2s ease;
                }
            }
            .editButton 
            {
                button
                {
                    background-color: $MainPurple;
                    color : #fcfcfc;
                    outline : none ;

                }
            }

            .Heading
            {
               
                font-family: "CatamaranBlack";
                height: 38px;
                padding: 0px 10px;
                margin-bottom: 8px;
                overflow: hidden;
                flex: 0 1 auto; 
                display: flex;
                flex-direction: column;
                font-size: 1.25rem;
                justify-content: center;
            }  
            .UserContainer
            {
                background-color: #f2f3f54d;
                width: 85%;
                border-radius: 10px;
                padding: 16px 32px 16px 32px;
                .UserHeader
                {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .userTh
                    {
                        display: flex;
                        align-items: center;
                        img
                        {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            box-shadow: 0px 0px 25px #06060731;
                            margin-right: 1.25rem;
                        }
                        .username
                        {
                            color : #060607;
                            font-family: 'CatamaranBold';
                            
                        }
                    }
                    
                }

                .UserInfo
                {
                    padding: 16px 32px 16px 32px;
                    background-color: #fcfcfc;
                    border-radius: 10px;
                    margin-top: 1.25rem;
                    .Section
                    {
                        padding: 10px 5px;
                        display: flex;
                        justify-content: space-between;
                        .hea
                        {
                            font-family: 'CatamaranBlack';
                            color : #6A7480;
                            font-size: 0.938rem;
                        }
                        .Value
                        {
                            display: inline-block;
                            margin-top: 5px;
                            font-family: 'CatamaranRegular';
                            color : #060607;
                        }
                    }
                }
                
            } 
            
        }
    }
}