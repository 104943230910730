@media only screen and  (max-width : 935px)
{
    .ModalContainer
    {
        .StorePreview
        {
            display: none !important;
        }
        .vendCont
        {
            display: flex;
            width: 100% !important;
            max-width: 100% !important;
            .VendDesc
            {
                width : 100% !important;
            }
        }
        
        .FormContainer
        {
            width: 80% !important;
            .GoogleSheetsContainer
            {
                flex-direction: column !important;
                .infoCont
                {
                    width: 100% !important;
                }
                .DemoContainer
                {
                    width: 100% !important;
                    margin-top: 20px;
                }
            }
        }
        .googleBut
        {
            width: 80% !important;

        }
        .SelectContainer
        {
            display: flex;
            flex-direction: column !important;
        }
        .ShopUrl
        {
            width:100% !important;
        }
        .selectForm
        {
            width: 100% !important;
        }
    }
}

.ModalContainer
{
    .ant-steps
    {
        font-family: 'CatamaranBold';
    }
    .title
    {
        font-family: 'CatamaranBold';
        font-size: 1.25rem;
        color : #2a2a2a;
    }
    
    .StoreTitle
    {
        font-family: 'CatamaranBlack';
        font-size: 1.563rem;
        transition: all ease-in-out 0.2s;
        padding: 5px;
    }
    .StorePreview
    {
        border-radius: 10px;
        padding: 45px 5px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all ease-in-out 0.2s;
        box-shadow: 0px 0px 25px #50505048;
        svg
        {
            width: 100px;
            height: 100px;
        }
    }
    .vendCont
    {
        display: flex;
        width: 52vw;
        max-width: 52vw;
        margin-top: 30px;
    }
    .VendDesc
    {
        width : 30%;
    }
    .VendorsContainer
    {
        padding: 15px 5px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .VendorItem
        {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 3px solid transparent;
            transition: all 0.2s ease-in-out;
        }
        .VendorItem svg
        {
            width: 45px;
            height: 45px;
        }
        .VendorItem:hover
        {
            border: 3px solid #2c133815;
        }
        .selected 
        {
            border: 3px solid $MainPurple;
        }
        .selected:hover
        {
            border: 3px solid $MainPurple;
        }
    }
    .Left
    {
        padding: 25px 10px;
        width: 48%;
        
    }
        
    .FormContainer
    {
        padding: 25px 30px;
        border-radius: 10px;
        background-color: #F6F6F6;
        width: 92%;
        .WebhookLinkContainer
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
            position: relative;
            width: 100%;
            .WebhookLink
            {
                box-sizing: border-box;
                font-family: "CatamaranBold";
                width: 100%;
                padding: 4px 11px;
                color: rgba(0, 0, 0, 0.5);
                font-size: 14px;
                line-height: 1.5715;
                background-color: #fff;
                background-image: none;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                transition: all 0.3s;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .text
                {
                    width : 80%
                }
                .Copy
                {
                    cursor: pointer;
                    margin-left: 25px;
                    padding-left: 10px;
                    border-left:1px solid #d9d9d9;
                    svg
                    {
                        padding-top: 5px;
                        fill : #2A2A2A;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
        .GoogleSheetsContainer
        {
            display: flex;
            justify-content: space-between;
            .infoCont
            {
                width: 45%;
            }
            .DemoContainer
            {
                width: 45%;
            }
            .YoutubeLink
            {
                display: flex;
                align-items: center;
                margin-top: 10px;
                span
                {
                    font-family: "CatamaranBold";
                    color: #2A2A2A;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
        }
        .tipTitle
        {
            font-family: 'CatamaranBlack' ;
            font-size: 1.25rem;
            color: $MainPurple;
        }
        .label
        {
            display: block;
            font-family: 'CatamaranBold' ;
            font-size: 1rem;
            color: #6A7480;
            margin: 14px 0 4px;
        }
        .inv
        { 
            background-color: #d8000b19 !important;
            box-shadow: 0px 0px 15px #2a2a2a29 !important;

        }
        .Field 
        {
            width: 100%;
            font-family: 'CatamaranBold' ;
            color: $MainPurple;
            outline: none;
            box-sizing: border-box;
            border: 2px solid transparent;
            background-color: #fcfcfc;
            padding: 10px 16px;
            border-radius: 10px;
            box-sizing: border-box;
            height: 40px;
            transition: all 0.2s ease;
            box-shadow: 0px 0px 15px #2a2a2a29;
        }
        .Field:hover
        {
            border: 2px solid $FadedPink;
        }
        .Field:focus
        {
            border: 2px solid $CreatePink;
        }
        .inv_msg
        { 
            margin-top: 4px;
            padding-left: 3px;
            padding-bottom: 4.5%;
            font-size: 13px;
            font-weight: bold;
            color : #D8000C ;
            font-family: 'Glacial indifference';
        
        }
        .SelectContainer
        {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
        .selectForm
        {
            width:100%;
        }
        .ShopUrl
        {
            width:100%;
        }
        .shopsUrl
        {
            width : 100%;
        }
    }
    
}